import { AppHeader } from "component/AppHeader/AppHeader";
import { Layout } from "antd";
import { Route, Navigate, Routes, BrowserRouter } from "react-router-dom";
import { lazy, Suspense } from "react";
import Home from "modules/home/Home";
import { Loader } from "component/Loader/Loader";
import { About } from "modules/about/about";

const ProfileCreationHome = lazy(
  () => import("consultProfileCreation/workflow/ProfileCreationHome")
);
const SettingController = lazy(
  () => import("modules/setting/settingController")
);
export const Framework = () => {
  const Temp = (props: any) => <b>{props.value}</b>;
  return (
    <Layout>
      <BrowserRouter>
        <AppHeader>
          <Suspense fallback={<Loader />}>
            <Routes>
              {/* Here add the route module wise */}
              <Route
                path="/*"
                element={<Navigate to="/home/" replace={true} />}
              />
              <Route path="/home/*" element={<Home />} />

              <Route path="/meeting/*" element={<Temp value={"meeting"} />} />
              <Route path="/time-rate/*" element={<Temp value={"time"} />} />
              <Route path="/history/*" element={<Temp value={"history"} />} />
              <Route path="/setting/*" element={<SettingController />} />
              <Route path="/about/*" element={<About />} />
              <Route path="/consult/*" element={<ProfileCreationHome />} />
            </Routes>
          </Suspense>
        </AppHeader>
      </BrowserRouter>
    </Layout>
  );
};
