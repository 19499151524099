import "./home.less";
import useSize from "styles/hook/useSize";
import { $HeightAdjustmentSpacing } from "styles/global";
import { Button, Card, Tabs, TabsProps } from "antd";

const Home = () => {
  const windowSize = useSize();
  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Consultants",
      children: <div>children</div>,
    },
    {
      key: "2",
      label: "History",
      children: <div>children</div>,
    },
    {
      key: "3",
      label: "Time slot and Rate",
      children: <div>children</div>,
    },
    {
      key: "4",
      label: "Wallet",
      children: "Content of Tab Pane 3",
    },
  ];
  return (
    <div
      style={{ height: windowSize[0] - $HeightAdjustmentSpacing }}
      className="home-container"
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Card
          style={{ width: "50%" }}
          actions={[<Button>Give feedback</Button>]}
        >
          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <div>
              <img
                alt="example"
                src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
              />
            </div>
            <div>
              <p>
                <b>Dr. Sadhya ruijdu</b> profile is claimed
              </p>
              <p>
                <b>Dr. Sadhya ruijdu</b> profile is claimed
              </p>
              <p>
                <b>Dr. Sadhya ruijdu</b> profile is claimed
              </p>
            </div>
          </div>
        </Card>
      </div>
      <div>
        <div>
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
      </div>
    </div>
  );
};

export default Home;
